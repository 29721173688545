<script setup>
import { onMounted } from "vue";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import OutboundOrders from "@/components/outbound-orders/OutboundOrders.vue";

onMounted(() => {
  setPageTitle("Outbound Orders");
});

</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>
  <v-card-subtitle>Use this section to manage outboundOrders. </v-card-subtitle>
  <div id="orders-grid-wrapper">
    <OutboundOrders />
  </div>
</template>

<style>
 #orders-grid-wrapper {
   height: 100%;
   padding-bottom: 110px;
 }
</style>