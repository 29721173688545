<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { ref, onBeforeMount, watch } from "vue";
import c3api from "@/c3api";
import { useToast } from "vue-toastification";
import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "@/stores/themeStore";
import {
    saveGridState,
    loadGridState,
    connectToServerApi,
    exportCsv
} from "@/utils/agGridUtils";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const toast = useToast();
let gridApi;
const statuses = ref([]);
const filter_status_id = ref(2);
const clients = ref([]);
const selectedClient = ref("");
const loadCount = ref(0);
const wHouses = ref([]);
const warehouseIds = ref([]);

const OUTBOUNDS_EXTERNAL_FILTERS_KEY = "outbounds-external-filter";
const GRID_SAVE_KEY = "outbounds";
const OUTBOUNDS_URL = "/outbound_orders";

let context = ref(null);

const defaultColDef = getDefaultColDefs();

const rangeFilterParams = {
    filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
    includeBlanksInLessThan: true,
    inRangeInclusive: true,
};

const valueGetters = {
    eta: (params) => params.data?.eta?.substring(0, 10),
    received_at: (params) => params.data?.received_at?.substring(0, 10),
    status: (params) => params.data?.order_status.name,
};

const cellRenderers = {
    status: (params) => {
        return params && params.data && params.data.order_status.name
            ? `<div class="badge badge-pill badge-dark" style="background: ${params.data.order_status.color}">${params.data.order_status.name}</div>`
            : "";
    },
};

const colDefs = ref([
    { field: "number", headerName: "Outbound #", cellDataType: "text" },
    {
        field: "client.name",
        headerName: "Client",
        cellDataType: "text",
        width: 135,
    },
    { field: "warehouse.name", headerName: "Warehouse", cellDataType: "text", width: 200 },
    { field: "po_number", headerName: "PO Number", cellDataType: "text", width: 200 },
    {
        field: "total_pieces",
        headerName: "Total Pcs.",
        cellDataType: "number",
        width: 115,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        filterParams: rangeFilterParams,
    },
    {
        field: "created_at",
        headerName: "Created On",
        cellDataType: "dateString",
        filter: "agDateColumnFilter",
        width: 135,
    },
    { field: "eta", headerName: "Ship On", cellDataType: "dateString", filter: "agDateColumnFilter", width: 110 },
    {
        field: "shipped_at",
        headerName: "Shipped",
        cellDataType: "dateString",
        filter: "agDateColumnFilter",
        filterParams: rangeFilterParams,
    },
    {
        field: "ship_name",
        headerName: "Destination",
        cellDataType: "text",
        width: 130,
    },
    { field: 'carrier.name', headerName: 'Carrier', cellDataType: 'text', },
    { field: 'order_status.name', colId: 'status', headerName: 'Status', cellDataType: 'text', minWidth: 250, filter: false, }
]);

const addFuncsToColDefs = function (colDefs) {
    for (let def of colDefs) {
        let colName = def.colId;
        if (valueGetters[colName]) def.valueGetter = valueGetters[colName];
        if (cellRenderers[colName]) def.cellRenderer = cellRenderers[colName];
    }
};
addFuncsToColDefs(colDefs.value);

onBeforeMount(async () => {
    const filters = {
        filters: JSON.stringify([
            {
                column: "outbound",
                condition: "eq",
                value: true,
            },
        ]),
    };

    let pStatus = c3api
        .get("/order_statuses", { params: filters })
        .then((statusesResponse) => {
            console.log("statusesResponse:");
            console.log(statusesResponse.data?.data);
            if (statusesResponse.data.data) {
                statusesResponse.data.data.unshift({ id: 0, name: "All", short_label: "All" });
                statuses.value = statusesResponse.data.data;
            }
        });

    let pClients = c3api
        .get("/clients", { params: { page_size: 500 } })
        .then((clientsResponse) => {
            console.log("clientsResponse:");
            console.log(clientsResponse.data?.data);
            if (clientsResponse.data.data) {
                clients.value = clientsResponse.data.data;
            }
        });

    let pWarehouses = c3api.get("/warehouses").then((warehousesResponse) => {
        console.log("warehousesResponse:");
        console.log(warehousesResponse.data?.data);
        if (warehousesResponse.data.data) {
            wHouses.value = warehousesResponse.data.data;
            if (warehouseIds.value.length <= 0) {
                warehouseIds.value.push(wHouses.value[0].id);
            }
        }
    });

    await Promise.allSettled([pStatus, pClients, pWarehouses]).catch((error) => {
        console.log(error);
        toast.error(error);
    });
});

const onGridReady = (params) => {
    gridApi = params.api;
    context.value = {
        apiUrl: OUTBOUNDS_URL,
        filterKey: OUTBOUNDS_EXTERNAL_FILTERS_KEY,
        advancedFilterKeys: advancedFilterKeys
    };
    loadGridState(GRID_SAVE_KEY, gridApi, colDefs.value);
    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
    reloadFilters();
};

const onRowClicked = function (event) {
    if (event.data.id) {
        gridApi.showLoadingOverlay();
        window.location = `/outbound-orders/${event.data.id}`;
    } else {
        console.log("Row clicked does not have an event.data.id!");
        console.log(event);
    }
};

const filtersChanged = function () {
    gridApi.onFilterChanged();
    saveFiltersToLocalStorage();
};

watch(warehouseIds, (warehouseIds) => {
    filtersChanged();
});

watch(filter_status_id, (filter_status_id) => {
    filtersChanged();
});

watch(selectedClient, (selectedClient) => {
    newInbound(selectedClient);
});

const onStateUpdated = function (params) {
    console.log("State updated", params.state);
    saveGridState(GRID_SAVE_KEY, gridApi);
    saveFiltersToLocalStorage();
};

const gridOptions = getDefaultGridOptions(colDefs.value);

const advancedFilterKeys = ["total_pieces", "created_at", "eta", "received_at"];

const resetFilters = function () {
    // console.log('resetFilters');
    gridApi.setFilterModel(null);
};

const newInbound = function (clientId) {
    window.location.href = "/outbound-orders/new?client_id=" + clientId;
};

const setStatusFilter = function (id) {
    // console.log("setStatusFilter, id: #{id}");
    filter_status_id.value = id;
    filtersChanged();
};

const reloadFilters = function () {
    // console.log("reloadFilters");

    const saved = window.localStorage.getItem("inboundFilter");
    if (saved) {
        let filters = JSON.parse(saved);
        if (filters.filter_status_id !== 0) filters.filter_status_id ||= 2;
        if (typeof filters.warehouseIds == "undefined") filters.warehouseIds = { 0: 1 };
        filter_status_id.value = filters.filter_status_id;
        warehouseIds.value = filters.warehouseIds;
        gridApi.setFilterModel(filters.agGrid);
        return filters;
    } else {
        return {};
    }
};

const saveFiltersToLocalStorage = function () {
    const filters = [
        {
            key: "status_id",
            value: filter_status_id.value,
        },
        {
            key: "warehouses",
            value: warehouseIds.value
        }
    ];
    window.localStorage.setItem("outbounds-external-filter", JSON.stringify(filters));
};

</script>

<template>
    <div id="nav-app">
        <div class="top-nav flex-wrap" id="top-nav">
            <div class="d-flex justify-end">
                <div class="d-flex">
                    <v-chip-group v-model="warehouseIds" multiple column mandatory>
                        <v-chip small filter outlined :value="w.id" v-for="w in wHouses" :key="w.id">
                            {{ w.nickname }}
                        </v-chip>
                    </v-chip-group>
                </div>
            </div>
        </div>
    </div>
    <div id="outbound-orders-wrapper" class="text-on-surface">
        <header>
            <h5 class="mb-0 text-on-surface">Outbound</h5>
            <ul class="nav nav-pills cf">
                <li class="nav-item top-item text-on-surface" v-for="status in statuses" :key="status.id">
                    <a class="nav-link text-on-surface" :class="{
                        'text-primary': status.id == filter_status_id,
                        'border-b-lg': status.id == filter_status_id,
                    }" href="#" @click="setStatusFilter(status.id)">
                        {{ status.short_label }}
                    </a>
                </li>
            </ul>
            <div class="d-flex">
                <v-btn class="btn-header" @click="resetFilters">Reset</v-btn>
                <v-btn class="btn-header" @click="() => {
                    const csvExportFunction  = exportCsv(gridApi);
                    csvExportFunction()
                }">Export</v-btn>
                <v-dialog width="500">
                    <template v-slot:activator="{ props }">
                        <v-btn v-if="false" class="btn-header" v-bind="props" text="New"> </v-btn>
                    </template>

                    <template v-slot:default="{ isActive }">
                        <v-card flat title="Create Outbound for:">
                            <v-autocomplete label="Select Client" v-model="selectedClient" :items="clients"
                                item-title="name" item-value="id" auto-select-first>
                            </v-autocomplete>
                            <v-card-actions>
                                <v-spacer></v-spacer>

                                <v-btn text="Close Dialog" @click="isActive.value = false"></v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </header>
        <AgGridVue style="width: 100%; height: 1000px" :class="themeStore.agTheme" :columnDefs="colDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady"
            @state-updated="onStateUpdated" @rowClicked="onRowClicked" :context="context"></AgGridVue>
    </div>
</template>

<style>
#outbound-orders-wrapper {
    width: 100%;
    height: 100%;

    & a {
        text-decoration: none;
    }

    & .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    & header {
        height: 50px;
        width: calc(100vw - 65px);
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: transparent;
        border-bottom: 1px solid #cfcfcf;
        padding: 0 1rem;
        z-index: 2;
    }

    & header h5 {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 0;
        font-size: 1.25rem;
    }

    & header .nav-pills .nav-link {
        background: transparent;
        padding: 0 1rem;
        border-radius: 0;
        border-bottom: 2px solid transparent;
        display: flex;
        color: inherit;
        justify-content: center;
        align-items: center;
    }

    & header .nav-pills .nav-link.active {
        background: transparent;
        color: inherit;
        border-bottom: 2px solid #1a66c0;
    }

    & header .btn {}

    & .btn-header,
    .btn-header:visited,
    .btn-header:active {
        height: 49px;
        padding: 0 1rem;
        border: 0;
        border-left: 1px solid #cfcfcf;
        font-weight: 500;
        font-size: 0.8rem;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.2);
        text-decoration: none;
        color: #4691f6;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 0;
        border-right: 1px solid transparent;
    }

    & .btn-header:last-child,
    .btn-header:visited:last-child,
    .btn-header:active:last-child {
        border-right: 1px solid #cfcfcf;
    }

    & .btn-header:focus {
        outline: 0;
    }

    & .btn-header:hover {
        text-decoration: none;
        background-color: #1a66c0;
        color: #fff;
    }

    & .btn-header:hover .svg-icon path,
    .btn-header:hover .svg-icon polygon,
    .btn-header:hover .svg-icon rect {
        fill: #fff;
    }

    & .btn-header:hover .svg-icon circle {
        stroke: #fff;
    }

    & .badge {
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    & .badge-pill {
        padding-right: 0.6em;
        padding-left: 0.6em;
        border-radius: 10rem;
    }

    & .badge-dark {
        color: #fff;
        background-color: #343a40;
    }
}
</style>
